/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { Link } from 'react-router-dom';

import { selectGradeCount } from 'helpers'
import { sanitizeURISlug } from 'helpers/routing';

import { CardInfo } from 'types/gameInfo';

interface CardGradeViewLinkProps {
  cardInfo: CardInfo;
  grade: string;
}

function CardGradeViewLink(props: CardGradeViewLinkProps) {
  const { cardInfo, grade } = props;
  const { finalGradeCounts, number, setName, gameCode } = cardInfo;
  const count = selectGradeCount(grade, finalGradeCounts);
  if (count) {
    return (
      <Link
        to={`/${gameCode}/${setName}/${sanitizeURISlug(number)}/${grade}`}
        css={css`
          font-size: 1.1rem;
        `}
      >
        {count}
      </Link>
    )
  }
  return null;
}

export default CardGradeViewLink;
