/* eslint-disable @typescript-eslint/naming-convention */
import {
  TableCell,
  TableRow,
} from "@mui/material";

import { CellGeneratedTableRow } from "types/tables";

const CellGeneratedRow = (props: any) => {
  const { cells, row } = props;

  return (
    <>
      <TableRow>
        {cells.map((cell: CellGeneratedTableRow) => {
          const { id, display, align = "left" } = cell;
          if (display) {
            return (
              <TableCell align={align} key={id}>
                {display(row)}
              </TableCell>
            );
          }
          return null;
        })}
      </TableRow>
    </>
  );
};

export default CellGeneratedRow;
