/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  Container,
  Grid,
} from '@mui/material';

const Footer = () => {
  return (
    <footer css={css`
      width: 100%;
      background-color: #debb68;
      padding: 40px 0 0 0;
    `}>
      <Container maxWidth='xl'>
        <Grid container>
          <Grid item xs={12} md={4} css={css`
            margin: auto 0;
            text-align: center;
            padding-bottom: 40px;
          `}>
            <ul css={css`
              margin: 0;
              padding: 0;
              list-style: none;
              li a {
                color: black;
              }
            `}>
              <li><a href='https://premiercardgrading.com'>US Grading</a></li>
              <li><a href='https://premiercardgrading.com.au'>AU Grading</a></li>
              <li><a href='https://premiercardgrading.co.nz'>NZ Grading</a></li>
            </ul>
          </Grid>
          <Grid item xs={12} md={4} css={css`
            margin: auto 0;
            text-align: center;
            padding-bottom: 40px;
          `}>
            © {new Date().getFullYear()} All rights reserved - Premier Card Grading
          </Grid>
          <Grid item xs={12} md={4} css={css`
            margin: auto 0;
            text-align: center;
            padding-bottom: 40px;
          `}>
            <ul css={css`
              margin: 0;
              padding: 0;
              list-style: none;
              li a {
                color: black;
              }
            `}>
              <li><a href='https://premiercardgrading.com/policies/privacy-policy'>Privacy Policy</a></li>
              <li><a href='https://premiercardgrading.com/policies/terms-of-service'>Terms of Service</a></li>
            </ul>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
