/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

import {
  Grid,
  Box,
  Typography,
} from '@mui/material';

import {
  sortSetViewTable,
} from 'helpers/tables';

interface GameSetsProps {
  data: any[];
  gameCode: string;
}

function GameSets(props: GameSetsProps) {
  const { data, gameCode } = props;

  return (
    <Grid container>
      {data.sort(sortSetViewTable('desc', 'cardsGraded')).map((set) => {
        const { id, setName, cardsGraded, releaseDate, setImage } = set;
        return (
          <Grid item xs={6} sm={4} md={3} key={id} padding={1}>
            <Box sx={{
              textAlign: 'center',
              border: '1px solid #212121',
              borderRadius: 2,
              padding: '18px 12px',
              height: '100%',
              boxSizing: 'border-box',
              display: 'flex',
            }}>
              <Box sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}>
                {setImage && (
                  <Link to={`/${gameCode}/${setName}`}>
                    <img 
                      src={setImage}
                      alt={`${gameCode} logo`}
                      css={css`
                        max-width: 100%;
                      `}
                    />
                  </Link>
                )}
                <Typography variant='h6' component={Link} to={`/${gameCode}/${setName}`}>
                  {set.setName}
                </Typography>
                <Typography>
                  Cards graded: {cardsGraded || 0}
                </Typography>
                {releaseDate && (
                  <Typography variant='caption'>
                    Release Date: {releaseDate}
                  </Typography>
                )}
              </Box>
            </Box>
          </Grid>
        )
      })}
    </Grid>
  );
}

export default GameSets;
