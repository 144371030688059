/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';

import Breadcrumbs from 'components/Breadcrumbs';
import FilterSearch from 'components/FilterSearch';
import GameSets from 'components/GameSets';
import GameSetsTable from 'components/Tables/GameSetsTable';

import { filterCardsGraded, filterRowContains } from 'helpers/tables';
import { useGetGameInfo } from 'services/pcgPublic';

import GridViewIcon from '@mui/icons-material/GridView';
import TableRowsIcon from '@mui/icons-material/TableRows';

function GameView() {
  const params = useParams();
  const { gameCode = '' } = params;
  
  const [ search, setSearch ] = useState('');
  const [ mode, setMode ] = useState('card');

  const { game, found, sets, description, gameImage } = useGetGameInfo(gameCode);
  
  useEffect(() => {
    setSearch('');
  }, [gameCode])

  if (!found) {
    return (
      <div>
        Game does not exist or is not supported.
      </div>
    )
  }

  const filteredData = sets?.slice().filter(filterCardsGraded(null)).filter(filterRowContains(search))

  return (
    <>
      <Helmet title={game || ''} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Breadcrumbs 
            crumbs={[
              {
                url: '/',
                text: 'Home',
              },
              {
                url: '',
                text: game || '',
              }
            ]} 
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <img 
            src={gameImage}
            alt={game}
            css={css`
              margin-bottom: 16px;
              width: 100%;
              max-width: 400px;
            `}
          />
          <Typography variant='subtitle1'>
            {description}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box sx={{ m: 1, lineHeight: '52px' }}>
              <Tooltip title="Card View">
                <IconButton 
                  color="primary" 
                  aria-label="card view"
                  component="span"
                  disabled={mode === 'card'}
                  onClick={() => setMode('card')}
                >
                  <GridViewIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ m: 1, lineHeight: '52px' }}>
              <Tooltip title="Table View">
                <IconButton 
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  disabled={mode === 'table'}
                  onClick={() => setMode('table')}
                >
                  <TableRowsIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box css={css` padding-right: 8px; `}>
              <FilterSearch 
                onChange={(value: string) => setSearch(value)}
                label='Search Sets'
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 0 }}>
          {mode === 'card' && (
            <GameSets
              data={filteredData}
              gameCode={gameCode}
            />
          )}
          {mode === 'table' && (
            <GameSetsTable
              data={filteredData}
              isFetching={false}
              gameCode={gameCode}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default GameView;
