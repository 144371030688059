
import { Link } from 'react-router-dom';
import { CardInfo } from 'types/gameInfo';

interface SetLinkProps {
  cardInfo: CardInfo | null;
  setName: string;
  onClick?: any;
}

const SetLink = ({cardInfo, setName, onClick = null} : SetLinkProps) => {
  if (!cardInfo?.gameCode && !cardInfo?.setName) {
    return <>{setName}</>;
  }

  return (
    <Link to={`/${cardInfo.gameCode}/${cardInfo.setName}`} onClick={onClick}>{setName}</Link>
  )
}

export default SetLink;