export const GRADES = [
  '10',
  '9.5',
  '9',
  '8.5',
  '8',
  '7.5',
  '7',
  '6',
  '5',
  '4',
  '3',
  '2',
  '1',
]