/* eslint-disable @typescript-eslint/naming-convention */
import {
  TableCell,
  TableRow,
} from "@mui/material";

interface NoResultsRowProps {
  colSpan: number;
  text?: string;
}

const NoResultsRow = (props: NoResultsRowProps) => {
  const { colSpan, text } = props;

  return (
    <TableRow data-testid="no-results-display">
      <TableCell colSpan={colSpan} style={{ textAlign: "center" }}>
        <em>{text}</em>
      </TableCell>
    </TableRow>
  );
};

NoResultsRow.defaultProps = {
  text: "No results found",
};

export default NoResultsRow;
