/** @jsxImportSource @emotion/react */
import { useState, useCallback } from 'react';
import { debounce } from 'lodash';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';

interface FilterSearchProps {
  onChange: any;
  label?: string;
}

const FilterSearch = (props: FilterSearchProps) => {
  const { onChange, label = 'Search Cards' } = props;
  const [value, setValue] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebounce = useCallback(
    debounce((_searchVal: string) => {
      onChange(_searchVal);
    }, 300),
    []
  );

  const handleOnChange = (value: string) => {
    setValue(value);
    handleDebounce(value);
  }

  return (
    <div style={{ paddingRight: 8, width: 300 }}>
      <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
        <InputLabel htmlFor="filter-search-input">{label}</InputLabel>
        <OutlinedInput
          id="filter-search-input"
          type='text'
          value={value}
          onChange={(event) => handleOnChange(event.target.value)}
          endAdornment={
            value ? (
              <InputAdornment position="end" onClick={() => handleOnChange('')} style={{ cursor: 'pointer '}}>
                <ClearIcon />
              </InputAdornment>
            ) : (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            )
          }
          label={label}
        />
      </FormControl>
    </div>
  );
};

export default FilterSearch;