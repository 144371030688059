/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import {
  Box,
  Typography,
} from '@mui/material';

import { CardInfo } from 'types/gameInfo';

interface CardPreviewProps {
  cardInfo: CardInfo | null;
  isFetching: boolean;
}

function CardPreview(props: CardPreviewProps) {
  const { cardInfo, isFetching } = props;

  if (isFetching) {
    return (
      <div css={css`
        text-align: center;
        width: 100%;
        font-style: italic;
      `}>
        Loading...
      </div>
    )
  }

  if (!cardInfo) {
    return (
      <div css={css`
        text-align: center;
        width: 100%;
        font-style: italic;
      `}>
        Card not found.
      </div>
    )
  }

  const {
    cardName, rarity, number, image, releaseDate, setName
  } = cardInfo || {};

  return (
    <Box>
      <img 
        src={image}
        alt='PCG Logo'
        css={css`
          max-width: 260px;
          width: 100%;
          margin-bottom: 16px;
        `}
      />
      <Typography variant='subtitle1' gutterBottom>
        <strong>Card Name:</strong> {cardName}
      </Typography>
      <Typography variant='subtitle1' gutterBottom>
        <strong>Set:</strong> {setName}
      </Typography>
      <Typography variant='subtitle1' gutterBottom>
        <strong>Number:</strong> {number}
      </Typography>
      <Typography variant='subtitle1' gutterBottom>
        <strong>Rarity:</strong> {rarity}
      </Typography>
      <Typography variant='subtitle1' gutterBottom>
        <strong>Release Date:</strong> {releaseDate}
      </Typography>
    </Box>
  );
}

export default CardPreview;
