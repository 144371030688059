/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState, useEffect, useReducer } from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Skeleton
} from "@mui/material";

import {
  tableReducer,
  dispatchFiltersChange,
  dispatchChangePage,
  dispatchChangeRowsPerPage,
  sortSetViewTable
} from "helpers/tables";

import { sanitizeURISlug } from "helpers/routing";

import { DEFAULT_TABLE_REDUCER_VALUES } from "constants/tables";

import EnhancedTableHead from "components/Tables/EnhancedTableHead";
import CellGeneratedRow from "components/Tables/CellGeneratedRow";
import EnhancedPagination from "components/Tables/EnhancedPagination";
import NoResultsRow from "components/Tables/NoResultsRow";
import CardGradeViewLink from "components/CardGradeViewLink";

import { CardInfo } from "types/gameInfo";
import { CellGeneratedTableRow } from "types/tables";

interface SetViewTableProps {
  data: any[];
  isFetching: boolean;
  gameCode: string;
  setName: string;
}

function SetViewTable(props: SetViewTableProps) {
  const { data, isFetching, gameCode, setName } = props;

  const [count, setCount] = useState(0);
  const [showAll, setShowAll] = useState(false);

  const [variables, dispatch] = useReducer(tableReducer, {
    ...DEFAULT_TABLE_REDUCER_VALUES,
    orderBy: "cardsGraded",
    limit: 25
  });

  const { page, limit, order, orderBy } = variables;

  useEffect(() => {
    if (!isFetching) {
      setCount(data?.length || 0);
    }
  }, [data, isFetching]);

  const HEAD_CELLS: CellGeneratedTableRow[] = [
    {
      id: "number",
      label: "Card #",
      width: 100,
      display: ({ number }: CardInfo) => number
    },
    {
      id: "title",
      label: "Card Name / Details",
      display: ({ title, rarity, number }: CardInfo) => {
        return (
          <>
            <Link to={`/${gameCode}/${setName}/${sanitizeURISlug(number)}`}>
              {title}
            </Link>
            <br />
            <em>{rarity.charAt(0).toUpperCase() + rarity.slice(1)}</em>
          </>
        );
      }
    },
    {
      id: "cardsGraded",
      align: "center",
      width: 120,
      label: "Total Grades",
      display: ({ cardsGraded, number }: CardInfo) => {
        return (
          <Link
            to={`/${gameCode}/${setName}/${sanitizeURISlug(number)}`}
            css={css`
              font-size: 1.1rem;
            `}
          >
            {cardsGraded}
          </Link>
        );
      }
    },
    {
      id: "pristine_ten_gold",
      sortable: false,
      align: "center",
      width: 100,
      label: "Pristine 10 (Quad)",
      display: (cardInfo: CardInfo) => (
        <CardGradeViewLink cardInfo={cardInfo} grade={"Gold 10.0"} />
      )
    },
    {
      id: "pristine_ten",
      sortable: false,
      align: "center",
      width: 100,
      label: "Pristine 10",
      display: (cardInfo: CardInfo) => (
        <CardGradeViewLink cardInfo={cardInfo} grade={"10.0"} />
      )
    },
    {
      id: "gem_mint_ten",
      sortable: false,
      align: "center",
      width: 100,
      label: "Gem Mint 10",
      display: (cardInfo: CardInfo) => (
        <CardGradeViewLink cardInfo={cardInfo} grade={"Gem Mint 10.0"} />
      )
    },
    {
      id: "nine_five",
      sortable: false,
      width: 50,
      align: "center",
      label: "9.5",
      display: (cardInfo: CardInfo) => (
        <CardGradeViewLink cardInfo={cardInfo} grade={"9.5"} />
      )
    },
    {
      id: "nine",
      sortable: false,
      width: 50,
      align: "center",
      label: "9",
      display: (cardInfo: CardInfo) => (
        <CardGradeViewLink cardInfo={cardInfo} grade={"9.0"} />
      )
    },
    {
      id: "eight_five",
      sortable: false,
      width: 50,
      align: "center",
      label: "8.5",
      display: (cardInfo: CardInfo) => (
        <CardGradeViewLink cardInfo={cardInfo} grade={"8.5"} />
      )
    },
    {
      id: "eight",
      sortable: false,
      width: 50,
      align: "center",
      label: "8",
      display: (cardInfo: CardInfo) => (
        <CardGradeViewLink cardInfo={cardInfo} grade={"8.0"} />
      )
    },
    {
      id: "seven_five",
      sortable: false,
      width: 50,
      align: "center",
      label: "7.5",
      display: (cardInfo: CardInfo) => (
        <CardGradeViewLink cardInfo={cardInfo} grade={"7.5"} />
      )
    },
    {
      id: "seven",
      sortable: false,
      width: 50,
      align: "center",
      label: "7",
      display: (cardInfo: CardInfo) => (
        <CardGradeViewLink cardInfo={cardInfo} grade={"7.0"} />
      )
    }
  ];

  const ADDITIONAL_HEAD_CELLS: CellGeneratedTableRow[] = [
    {
      id: "six",
      sortable: false,
      width: 50,
      align: "center",
      label: "6",
      display: (cardInfo: CardInfo) => (
        <CardGradeViewLink cardInfo={cardInfo} grade={"6.0"} />
      )
    },
    {
      id: "five",
      sortable: false,
      width: 50,
      align: "center",
      label: "5",
      display: (cardInfo: CardInfo) => (
        <CardGradeViewLink cardInfo={cardInfo} grade={"5.0"} />
      )
    },
    {
      id: "four",
      sortable: false,
      width: 50,
      align: "center",
      label: "4",
      display: (cardInfo: CardInfo) => (
        <CardGradeViewLink cardInfo={cardInfo} grade={"4.0"} />
      )
    },
    {
      id: "three",
      sortable: false,
      width: 50,
      align: "center",
      label: "3",
      display: (cardInfo: CardInfo) => (
        <CardGradeViewLink cardInfo={cardInfo} grade={"3.0"} />
      )
    },
    {
      id: "two",
      sortable: false,
      width: 50,
      align: "center",
      label: "2",
      display: (cardInfo: CardInfo) => (
        <CardGradeViewLink cardInfo={cardInfo} grade={"2.0"} />
      )
    },
    {
      id: "one",
      sortable: false,
      width: 50,
      align: "center",
      label: "1",
      display: (cardInfo: CardInfo) => (
        <CardGradeViewLink cardInfo={cardInfo} grade={"1.0"} />
      )
    }
  ];

  const END_HEAD_CELL: CellGeneratedTableRow = {
    id: "authenticated",
    sortable: false,
    width: 50,
    align: "center",
    label: "A",
    display: (cardInfo: CardInfo) => (
      <CardGradeViewLink cardInfo={cardInfo} grade={"0.0"} />
    )
  };

  const filteredData =
    data?.slice().sort(sortSetViewTable(order, orderBy)) || [];

  const CURRENT_HEAD_CELLS = showAll
    ? [...HEAD_CELLS, ...ADDITIONAL_HEAD_CELLS, END_HEAD_CELL]
    : [...HEAD_CELLS, END_HEAD_CELL];

  return (
    <Paper variant="outlined">
      <div
        css={css`
          width: 100%;
          text-align: right;
          padding-top: 8px;
        `}
      >
        <Button
          onClick={() => setShowAll(!showAll)}
          css={css`
            color: black;
          `}
        >
          {showAll ? "Hide Lower Grades" : "Show All Grades"}
        </Button>
      </div>
      <TableContainer>
        <Table>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={(_e: any, property: string) =>
              dispatchFiltersChange(
                { key: "sort", property, orderBy, order },
                dispatch
              )
            }
            headCells={CURRENT_HEAD_CELLS}
          />
          <TableBody>
            {isFetching ? (
              Array.from(Array(limit)).map((_e: any, i: number) => {
                const key = `empty_${i}`;
                return (
                  <TableRow key={key}>
                    <TableCell colSpan={CURRENT_HEAD_CELLS.length}>
                      <Skeleton height={28} />
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <>
                {filteredData?.length > 0 ? (
                  filteredData
                    ?.slice(page * limit, page * limit + limit)
                    .map((row: any) => (
                      <CellGeneratedRow
                        key={`${row.id}`}
                        row={row}
                        cells={CURRENT_HEAD_CELLS}
                      />
                    ))
                ) : (
                  <NoResultsRow
                    colSpan={CURRENT_HEAD_CELLS.length}
                    data-testid="no-results-display"
                  />
                )}
              </>
            )}
            <TableRow>
              <EnhancedPagination
                count={count}
                rowsPerPage={limit}
                page={count === 0 ? 0 : page}
                onChangePage={(newPage: number) =>
                  dispatchChangePage(newPage, limit, dispatch)
                }
                onChangeRowsPerPage={(rowsPerPage: number) =>
                  dispatchChangeRowsPerPage(rowsPerPage, dispatch)
                }
              />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default SetViewTable;
