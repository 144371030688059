/* eslint-disable @typescript-eslint/naming-convention */
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";

import {
  EnhancedTableHeadProps,
  CellGeneratedTableRow,
} from "types/tables";

const EnhancedTableHead = (props: EnhancedTableHeadProps) => {
  const { order, orderBy, onRequestSort, headCells } = props;

  const createSortHandler = (property: any) => (event: any) => {
    if (onRequestSort) {
      onRequestSort(event, property);
    }
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: CellGeneratedTableRow) => {
          const { 
            id,
            label,
            empty,
            sortable = true,
            width = undefined,
            align = "left",
            disablePadding = false,
          } = headCell;
          
          if (empty) {
            return (
              <TableCell 
                key={id}
                width={width}
              />
            );
          }

          if (!sortable) {
            return ( 
              <TableCell
                key={id}
                align={align}
                padding={disablePadding ? "none" : "normal"}
                width={width}
              >
                {label}
              </TableCell>
            );
          }

          return (
            <TableCell
              key={id}
              align={align}
              padding={disablePadding ? "none" : "normal"}
              sortDirection={orderBy === id ? order : false}
              width={width}
            >
              <TableSortLabel
                active={orderBy === id}
                direction={orderBy === id ? order : "desc"}
                onClick={createSortHandler(id)}
              >
                {label}
                {orderBy === id ? (
                  <span 
                    style={{
                      border: 0,
                      clip: "rect(0 0 0 0)",
                      height: 1,
                      margin: -1,
                      overflow: "hidden",
                      padding: 0,
                      position: "absolute",
                      top: 20,
                      width: 1,
                    }}
                  >
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );

        })}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
