/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { grey } from '@mui/material/colors';

import { useIsMinWidth } from 'hooks';

import {
  Grid,
  Typography,
} from '@mui/material';

import { selectGradeColor } from 'helpers';

function Grade(props: { title: string, grade: string, gradeTitle?: string, full?: boolean }) {
  const { title, grade, gradeTitle = null, full = false } = props; 

  const isMinWidth = useIsMinWidth(700);

  const scale = isMinWidth ? 1.6 : 1.2;
  const radius = 52 * scale;
  const height = 60 * scale;
  const width = 120 * scale;

  const circumference = radius * 2 * Math.PI;
  const offset = ((10 - parseInt(grade)) * circumference) / 20;

  const gradeColor = selectGradeColor(grade);

  return (
    <Grid item xs={full ? 12 : 6} md={full ? 12 : 6} lg={full ? 12 : 3} textAlign='center' paddingBottom={'2rem'}>
      <div css={css`
        position: relative;
        width: fit-content;
        margin: auto;
      `}>
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#debb68" />
              <stop offset="20%" stopColor="#B38728" />
              <stop offset="60%" stopColor="#FCF6BA" />
              <stop offset="80%" stopColor="#AA771C" />
              <stop offset="100%" stopColor="#FCF6BA" />
            </linearGradient>
          </defs>
          <circle
            css={css`
              transform-origin: 50% 50%;
              stroke-width: 2;
            `}
            stroke={grey[500]}
            strokeWidth='7'
            fill='transparent'
            r={radius}
            cx={height}
            cy={height}
          />
          <circle
            css={css`
              transition: 0.35s stroke-dashoffset;
              transform-origin: 50% 50%;
              stroke-width: 7;
            `}
            stroke={gradeColor}
            strokeWidth='7'
            fill='transparent'
            r={radius}
            cx={height}
            cy={height}
            strokeDashoffset={String(offset)}
            strokeDasharray={`${circumference} ${circumference}`}
          />
        </svg>
        <div css={css`
          position: absolute;
          width: 100%;
          bottom: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
        `}>
          <Typography variant='h4' marginBottom={-1}>
            {grade}
          </Typography>
          <Typography 
            variant='h6'
            css={css`
              font-weight: 900;
              font-size: 1rem;
            `}
          >
            {title}
          </Typography>
        </div>
      </div>
      {gradeTitle && (
        <Typography 
          variant='h4'
          css={css`
            font-weight: 900;
          `}
        >
          {gradeTitle}
        </Typography>
      )}
    </Grid>
  )
}

export default Grade;