
import { Link } from 'react-router-dom';

interface GradeReportLinkProps {
  gradedId: string;
  onClick?: any;
}

const GradeReportLink = ({gradedId, onClick = null} : GradeReportLinkProps) => {
  return (
    <Link to={`/report/${gradedId}`} onClick={onClick}>{gradedId}</Link>
  )
}

export default GradeReportLink;