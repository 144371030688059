/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

import {
  Box,
  Grid,
  Typography,
} from '@mui/material';

import Breadcrumbs from 'components/Breadcrumbs';
import { useGetGameOptions } from 'services/pcgPublic';

function HomeView() {
  const gameOptions = useGetGameOptions();

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Breadcrumbs 
            crumbs={[
              {
                url: '',
                text: 'Home',
              }
            ]} 
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            {gameOptions.map((entry) => {
              const { id, game, gameCode, gameImage, cardsGraded } = entry;
              return (
                <Grid item xs={6} sm={4} md={3} key={id} padding={1}>
                  <Box sx={{
                    textAlign: 'center',
                    border: '1px solid #212121',
                    borderRadius: 2,
                    padding: '18px 12px',
                    height: '100%',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                    <Box sx={{
                      display: 'flex',
                      flexGrow: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                      <Link 
                        to={`/${gameCode}`}
                      >
                        <img 
                          src={gameImage}
                          alt={game}
                          css={css`
                            width: 100%;
                          `}
                        />
                      </Link>
                    </Box>
                    <Box sx={{
                      flexDirection: 'row',
                    }}>
                      <Link 
                        to={`/${gameCode}`}
                      >
                        <Typography variant='h6'>
                          {game}
                        </Typography>
                      </Link>
                      <Typography>
                        Cards graded: {cardsGraded || 0}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default HomeView;
