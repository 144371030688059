/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';

import {
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';

import { useGetSearchOptions } from 'services/pcgPublic';

import { GRADES } from 'constants/index';

const SearchCardsAdvanced = (props: { onSearch: any } ) => {
  const { onSearch } = props;

  const searchOptions = useGetSearchOptions();
  const { options, gradeNames } = searchOptions;

  const [gradedId, setGradedId] = useState('');
  const [cardName, setCardName] = useState('');

  const [game, setGame] = useState('');
  const [set, setSet] = useState('');
  const [finish, setFinish] = useState('');
  
  const [grade, setGrade] = useState('');
  const [finalGrade, setFinalGrade] = useState('');
  const [centerGrade, setCenterGrade] = useState('');
  const [surfaceGrade, setSurfaceGrade] = useState('');
  const [cornersGrade, setCornersGrade] = useState('');
  const [edgesGrade, setEdgesGrade] = useState('');

  const handleGameChange = (game: string) => {
    setSet('');
    setFinish('');
    setGame(game);
  }

  const handleSetFinalGrade = (grade: string) => {
    setGrade('');
    if (grade === 'Gold 10.0') {
      setFinalGrade('10');
      setCenterGrade('10');
      setSurfaceGrade('10');
      setCornersGrade('10');
      setEdgesGrade('10');
    } else {
      setFinalGrade(grade);
    }
  }

  const handleSetGrade = (grade: string) => {
    setFinalGrade('');
    setGrade(grade);
  }

  const handleClear = () => {
    setGradedId('');
    setCardName('');
    handleGameChange('');
    setGrade('');
    setFinalGrade('');
    setCenterGrade('');
    setSurfaceGrade('');
    setCornersGrade('');
    setEdgesGrade('');
    onSearch({});
  }

  const handleSearch = () => {
    onSearch({
      game,
      set,
      finish,
      grade,
      finalGrade,
      centerGrade,
      surfaceGrade,
      cornersGrade,
      edgesGrade,
    });    
  }

  const setOptions = options[game]?.sets || [];
  const finishOptions = options[game]?.finish || [];

  return (
    <Grid container>
      <Grid item xs={12} sm={6} padding={1}>
        <TextField
          id='gradedId'
          label='Graded ID'
          size='small'
          value={gradedId}
          onChange={(e: any) => setGradedId(e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} padding={1}>
        <TextField
          id='cardName'
          label='Card Name'
          size='small'
          value={cardName}
          onChange={(e: any) => setCardName(e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} padding={1}>
        <FormControl fullWidth size='small'>
          <InputLabel id='game'>Game</InputLabel>
          <Select
            labelId='game'
            id='game'
            value={game}
            label='Game'
            onChange={(e: any) => handleGameChange(e.target.value)}
            size='small'
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {Object.keys(options).map((game: string) => <MenuItem value={game} key={game}>{game}</MenuItem> )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} padding={1}>
        <FormControl fullWidth size='small'>
          <InputLabel id='setName'>Set Name</InputLabel>
          <Select
            labelId='setName'
            id='setName'
            value={set}
            label='Set Name'
            onChange={(e: any) => setSet(e.target.value)}
            size='small'
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {setOptions.map((set: string) => <MenuItem value={set} key={set}>{set}</MenuItem> )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} padding={1}>
        <FormControl fullWidth size='small'>
          <InputLabel id='game'>Finish</InputLabel>
          <Select
            labelId='finish'
            id='finish'
            value={finish}
            label='finish'
            onChange={(e: any) => setFinish(e.target.value)}
            size='small'
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {finishOptions.map((finish: string) => <MenuItem value={finish} key={finish}>{finish}</MenuItem> )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} padding={1}>
        <Typography variant='subtitle1'>
          Grades
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} padding={1}>
        <FormControl fullWidth size='small'>
          <InputLabel id='finalGrade'>Final Grade</InputLabel>
          <Select
            labelId='finalGrade'
            id='finalGrade'
            value={finalGrade}
            label='Final Grade'
            onChange={(e: any) => handleSetFinalGrade(e.target.value)}
            size='small'
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            <MenuItem value='Gold 10.0'>
              Gold 10.0
            </MenuItem>
            {GRADES.map((grade: string) => <MenuItem value={grade} key={grade}>{grade}</MenuItem> )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} padding={1}>
        <FormControl fullWidth size='small'>
          <InputLabel id='grade'>Grade</InputLabel>
          <Select
            labelId='grade'
            id='grade'
            value={grade}
            label='Grade'
            onChange={(e: any) => handleSetGrade(e.target.value)}
            size='small'
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {gradeNames.map((grade: string) => <MenuItem value={grade} key={grade}>{grade}</MenuItem> )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} padding={1}>
        <FormControl fullWidth size='small'>
          <InputLabel id='centerGrade'>Center Grade</InputLabel>
          <Select
            labelId='centerGrade'
            id='centerGrade'
            value={centerGrade}
            label='Center Grade'
            onChange={(e: any) => setCenterGrade(e.target.value)}
            size='small'
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {GRADES.map((grade: string) => <MenuItem value={grade} key={grade}>{grade}</MenuItem> )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} padding={1}>
        <FormControl fullWidth size='small'>
          <InputLabel id='surfaceGrade'>Surface Grade</InputLabel>
          <Select
            labelId='surfaceGrade'
            id='surfaceGrade'
            value={surfaceGrade}
            label='Surface Grade'
            onChange={(e: any) => setSurfaceGrade(e.target.value)}
            size='small'
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {GRADES.map((grade: string) => <MenuItem value={grade} key={grade}>{grade}</MenuItem> )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} padding={1}>
        <FormControl fullWidth size='small'>
          <InputLabel id='cornersGrade'>Corners Grade</InputLabel>
          <Select
            labelId='cornersGrade'
            id='cornersGrade'
            value={cornersGrade}
            label='Corners Grade'
            onChange={(e: any) => setCornersGrade(e.target.value)}
            size='small'
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {GRADES.map((grade: string) => <MenuItem value={grade} key={grade}>{grade}</MenuItem> )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} padding={1}>
        <FormControl fullWidth size='small'>
          <InputLabel id='edgesGrade'>Edges Grade</InputLabel>
          <Select
            labelId='edgesGrade'
            id='edgesGrade'
            value={edgesGrade}
            label='Edges Grade'
            onChange={(e: any) => setEdgesGrade(e.target.value)}
            size='small'
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {GRADES.map((grade: string) => <MenuItem value={grade} key={grade}>{grade}</MenuItem> )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} padding={1} textAlign='right'>
        <Button
          onClick={() => handleClear()}
          css={css`
            margin-right: 16px;
          `}
        >
          Clear
        </Button>
        <Button
          onClick={() => handleSearch()}
          variant='contained'
          css={css`
            color: white;
          `}
        >
          Search
        </Button>
      </Grid>
    </Grid>
  );
};

export default SearchCardsAdvanced;