export const selectGradeCount = (grade: string, finalGradeCounts: any[]) => {
  if (finalGradeCounts && finalGradeCounts.length > 0) {
    let found = finalGradeCounts.find((row: any) => String(row.grade) === String(grade))
    if (found) {
      return found.count
    }
  }
  return null;
}

export const selectGradeColor = (grade: string, svg: boolean = true) => {
  return '#debb68';
  // switch (grade) {
  //   case '10':
  //     if (svg) {
  //       return 'url(#gradient)';
  //     }
  //     return '#debb68';
  //   case '9.5':
  //     return '#debb68';
  //   case '9':
  //     return '#d1a96f';
  //   case '8.5':
  //     return '#cf974f';
  //   case '8':
  //     return '#deb887';
  //   case '7.5':
  //     return '#c66b27';
  //   case '7':
  //     return '#ab7440';
  //   default:
  //     return '#8e8e9a';
  // }
}