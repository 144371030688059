/** @jsxImportSource @emotion/react */
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";

import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { css } from "@emotion/react";
import { Link } from "react-router-dom";

import { APPBAR_HEIGHT } from "constants/layout";
import { COLORS } from "constants/colors";

import LogoImage from "images/pcg-logo.png";

import { useGetGameOptions } from "services/pcgPublic";

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const gameOptions = useGetGameOptions();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position="static"
      css={css`
        height: ${APPBAR_HEIGHT}px;
        background-color: ${COLORS.DARK};
        background-image: linear-gradient(
          315deg,
          #424242 0%,
          ${COLORS.DARK} 94%
        );
        box-shadow: none;
      `}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1 }}>
            <IconButton
              size="large"
              aria-label="game options"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              css={css`
                color: white;
              `}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {gameOptions.map((game) => (
                <MenuItem
                  key={game.gameCode}
                  onClick={handleCloseNavMenu}
                  component={Link}
                  to={`/${game.gameCode}`}
                >
                  <Typography textAlign="center">{game.game}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box>
            <Link to="/">
              <img
                src={LogoImage}
                alt="PCG Logo"
                css={css`
                  height: ${APPBAR_HEIGHT}px;
                `}
              />
            </Link>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default ResponsiveAppBar;
