/* eslint-disable */
import { useCallback } from "react";
import { format, subDays } from "date-fns";
import { getQueryParams, updateParams } from "helpers/routing";
import { PaginationQueryParams, TableQueryParams } from "types/tables";
import { CardInfo, Set } from "types/gameInfo";
import { DEFAULT_TABLE_REDUCER_VALUES } from "constants/tables";

export const getSearchValues = (search: string) => {
  if (!search) {
    return {
      searchString: "%%",
      searchNumber: null,
    };
  };
  const numbered = parseInt(search, 10);
  return {
    searchString: `%${search.trim()}%`,
    searchNumber: !Number.isNaN(numbered) ? search : 0,
  };
};

export const getQueryStatus = (status: string, allStatus: string, statuses: string[]) => {
  if (status === allStatus) {
    return statuses;
  };
  return [status];
};

export const getCalculatedDate = (dateType: number) => {
  if (!dateType || dateType <= 0) {
    return "2020-01-01";
  };
  return format(subDays(new Date(), dateType), "yyyy-MM-dd");
};

export const calculateOffset = (page: number, limit: number) => {
  if (page && limit) {
    return page * limit;
  }
  return 0;
};

export const getTableQueryParams = (defaults: PaginationQueryParams = {}): TableQueryParams => {
  const { page, perPage, order, orderBy, status, dateRange, search } = getQueryParams();

  const paramsPage = +page || defaults.page || DEFAULT_TABLE_REDUCER_VALUES.page;
  const paramsLimit = +perPage || defaults.limit || DEFAULT_TABLE_REDUCER_VALUES.limit;

  return {
    paramsPage,
    paramsLimit,
    paramsOffset: defaults.offset || calculateOffset(paramsPage, paramsLimit),
    paramsOrder: order || defaults.order || DEFAULT_TABLE_REDUCER_VALUES.order,
    paramsOrderBy: orderBy || defaults.orderBy || DEFAULT_TABLE_REDUCER_VALUES.orderBy,
    paramsStatus: status || defaults.status || DEFAULT_TABLE_REDUCER_VALUES.status,
    paramsDateRange: dateRange || defaults.dateRange || DEFAULT_TABLE_REDUCER_VALUES.dateRange,
    paramsSearch: search || defaults.search || DEFAULT_TABLE_REDUCER_VALUES.search,
  };
};

export const assignParamsToReducer = (queryParams: TableQueryParams): PaginationQueryParams => {
  const {
    paramsPage,
    paramsOffset,
    paramsLimit,
    paramsOrder,
    paramsOrderBy,
    paramsStatus,
    paramsDateRange,
    paramsSearch,
  } = queryParams;

  return {
    page: paramsPage,
    offset: paramsOffset,
    limit: paramsLimit,
    order: paramsOrder,
    orderBy: paramsOrderBy,
    status: paramsStatus,
    dateRange: paramsDateRange,
    search: paramsSearch,
  };
};

export const tableReducer = (state: any, action: any) => {

  switch (action.type) {
    case "offset":
      return {
        ...state,
        offset: action.payload.offset,
        page: action.payload.page,
      };
    case "limit":
      return {
        ...state,
        page: 0,
        offset: 0,
        limit: action.payload,
      };
    case "sort":
      return {
        ...state,
        order: action.payload.order,
        orderBy: action.payload.orderBy,
      };
    case "status":
      return {
        ...state,
        page: 0,
        offset: 0,
        status: action.payload,
      };
    case "dateRange":
      return {
        ...state,
        page: 0,
        offset: 0,
        dateRange: action.payload,
      };
    case "search":
      return {
        ...state,
        page: 0,
        offset: 0,
        search: action.payload,
      };
    default:
      return state;
  }
};

export const dispatchFiltersChange = (e: any, dispatch: any, history: any = null) => {
  const { key, value } = e;
  switch (key) {
    case "order":
      if (history) {
        return updateParams(key, String(value), history);
      }
      return null;
    case "orderBy":
      if (history) {
        return updateParams(key, String(value), history);
      }
      return null;
    case "status":
      if (history) {
        updateParams("page", "0", history);
        return updateParams(key, String(value), history);
      }
      return dispatch({ type: key, payload: value });
    case "sort": {
      const { property, orderBy, order } = e;

      const isAsc = (orderBy === property) ? (
        order === "asc"
      ) : (
        true
      );

      const orderValue = isAsc ? "desc" : "asc";

      if (history) {
        updateParams("orderBy", orderBy, history);
        updateParams("order", orderValue, history);
      }

      return dispatch({
        type: "sort",
        payload: {
          order: orderValue,
          orderBy: property
        }
      });
    }
    case "dateRange":
      if (history) {
        updateParams("page", "0", history);
        return updateParams(key, value, history);
      }
      return dispatch({ type: key, payload: value });
    case "search":
      if (history) {
        updateParams("page", "0", history);
        return updateParams(key, String(value), history);
      }
      return dispatch({ type: key, payload: value });
    default:
  }
  return null;
};

export const dispatchChangePage = (newPage: number, limit: number, dispatch: any) => {
  dispatch({ type: "offset", payload: { offset: calculateOffset(newPage, limit), page: newPage } });
};

export const dispatchChangeRowsPerPage = (rowsPerPage: number, dispatch: any) => {
  dispatch({ type: "limit", payload: rowsPerPage });
};

/**
 * filterCardsGraded: filter function for non-case matching flatened rows
 *
 */
 export const filterCardsGraded = (gradeValue: string | null = null) => (row: any) => {
   if (gradeValue && gradeValue !== '') {
    return row.cardsGraded !== null 
      && row.finalGradeCounts.length > 0
      && row.finalGradeCounts.filter((grade: any) => String(grade.grade) === gradeValue).length > 0
   }
   return row.cardsGraded !== null
 };


/**
 * filterRowContains: filter function for non-case matching flatened rows
 *
 * @param {string} needle
 */
export const filterRowContains = (needle: string) => (row: any) => JSON.stringify(row).toLowerCase().includes(needle.toLowerCase());

/**
 * useCallbackRefetch: hook to handle Apollo refetch undefined error
 *
 * @param {function} refetch
 * @url https://github.com/apollographql/apollo-client/issues/6816
 */
 export const useCallbackRefetch = (refetch: any) => useCallback(() => { setTimeout(() => refetch(), 0); }, [refetch]);

export const sortSetViewTable = (
  order: "asc" | "desc",
  orderBy: keyof CardInfo
) => (a: CardInfo, b: CardInfo) => {
  try {
    const aVal = a[orderBy];
    const bVal = b[orderBy];

    if (typeof aVal === "string" && typeof bVal === "string") {
      if (order === "desc") {
        return aVal.localeCompare(bVal, "en", { "sensitivity": "base" });
      }
      return aVal.localeCompare(bVal, "en", { "sensitivity": "base" }) === 1 ? -1 : 1;
    }

    if (typeof aVal === "number" && typeof bVal === "number") {
      if (aVal === bVal) {
        return 0;
      }
      if (order === "asc") {
        return aVal - bVal;
      }
      return bVal - aVal;
    }

    return 0;
  } catch (err) {
    return 0;
  }
};

export const sortCardViewTable = (
  order: "asc" | "desc",
  orderBy: keyof CardInfo
) => (a: CardInfo, b: CardInfo) => {
  try {
    const aVal = a[orderBy];
    const bVal = b[orderBy];

    if (typeof aVal === "string" && typeof bVal === "string") {
      if (order === "desc") {
        return aVal.localeCompare(bVal, "en", { "sensitivity": "base" });
      }
      return aVal.localeCompare(bVal, "en", { "sensitivity": "base" }) === 1 ? -1 : 1;
    }

    if (typeof aVal === "number" && typeof bVal === "number") {
      if (aVal === bVal) {
        return 0;
      }
      if (order === "asc") {
        return aVal - bVal;
      }
      return bVal - aVal;
    }

    return 0;
  } catch (err) {
    return 0;
  }
};

export const sortGameSetsTable = (
  order: "asc" | "desc",
  orderBy: keyof Set
) => (a: Set, b: Set) => {
  try {
    const aVal = a[orderBy];
    const bVal = b[orderBy];

    if (typeof aVal === "string" && typeof bVal === "string") {
      if (order === "desc") {
        return aVal.localeCompare(bVal, "en", { "sensitivity": "base" });
      }
      return aVal.localeCompare(bVal, "en", { "sensitivity": "base" }) === 1 ? -1 : 1;
    }

    if (typeof aVal === "number" && typeof bVal === "number") {
      if (aVal === bVal) {
        return 0;
      }
      if (order === "asc") {
        return aVal - bVal;
      }
      return bVal - aVal;
    }

    return 0;
  } catch (err) {
    return 0;
  }
};