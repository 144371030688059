
import { Link } from 'react-router-dom';
import { CardInfo } from 'types/gameInfo';
import { sanitizeURISlug } from 'helpers/routing';

interface CardLinkProps {
  cardInfo: CardInfo | null;
  cardName: string;
  onClick?: any;
}

const CardLink = ({cardInfo, cardName, onClick = null} : CardLinkProps) => {
  if (!cardInfo?.gameCode && !cardInfo?.setName && !cardInfo?.number) {
    return <>{cardName}</>;
  }

  return (
    <Link to={`/${cardInfo.gameCode}/${cardInfo.setName}/${sanitizeURISlug(cardInfo.number)}`} onClick={onClick}>{cardName}</Link>
  )
}

export default CardLink;