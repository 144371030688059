import { NavigateFunction } from "react-router-dom";

export const getURLSearchParams = () => {
  return new URLSearchParams(window.location.search);
};

export const getQueryParams = () => {
  const params = getURLSearchParams();
  const results: any = {};
 
  params.forEach((v, k) => {
    results[k] = v;
  });

  return results;
};

export const updateParams = (name: string, value: string, navigate: NavigateFunction ) => {
  const params = getURLSearchParams();
  params.set(name, value);
  navigate({
    search: params.toString()
  });
};

export const sanitizeURISlug = (slug: string | undefined) => {
  if (!slug) {
    return slug;
  }
  return slug.replace('/', '%2F').replace('&', '%26');
}
