import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

import { Box, Grid } from "@mui/material";

import FilterSearch from "components/FilterSearch";
import CardViewTable from "components/Tables/CardViewTable";
import Breadcrumbs from "components/Breadcrumbs";
import CardPreview from "components/CardPreview";

import { sanitizeURISlug } from "helpers/routing";
import { filterRowContains } from "helpers/tables";
import { useGetGameInfo, useGetCardGradeViewQuery } from "services/pcgPublic";
import { FinishTable } from "components/FinishTable";

function CardGradeView() {
  const params = useParams();
  const { gameCode = "", setName = "", number = "", finalGrade = "" } = params;

  const [search, setSearch] = useState("");

  const gameInfo = useGetGameInfo(gameCode);
  const { data, cardInfo, isFetching } = useGetCardGradeViewQuery({
    gameCode,
    setName,
    number,
    finalGrade
  });

  useEffect(() => {
    setSearch("");
  }, [gameCode, setName]);

  const filteredData = data?.slice().filter(filterRowContains(search)) || [];

  return (
    <>
      <Helmet title={setName || ""} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Breadcrumbs
            crumbs={[
              {
                url: "/",
                text: "Home"
              },
              {
                url: `/${gameCode}`,
                text: gameInfo.game || ""
              },
              {
                url: `/${gameCode}/${setName}`,
                text: setName || ""
              },
              {
                url: `/${gameCode}/${setName}/${sanitizeURISlug(number)}`,
                text: `${cardInfo?.cardName} (#${number})` || number || ""
              },
              {
                url: "",
                text: finalGrade || ""
              }
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <CardPreview cardInfo={cardInfo} isFetching={isFetching} />
          <FinishTable data={filteredData} />
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box>
              <FilterSearch onChange={(value: string) => setSearch(value)} />
            </Box>
          </Box>
          <CardViewTable data={filteredData} isFetching={isFetching} />
        </Grid>
      </Grid>
    </>
  );
}

export default CardGradeView;
