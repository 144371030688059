/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import {
  Box,
  Grid,
  Typography,
} from '@mui/material';

import {
  grey,
} from '@mui/material/colors';

import { useGetGradeReportViewQuery } from 'services/pcgPublic';

import { COLORS } from 'constants/colors';

import Grade from 'components/Grade';
import CardLink from 'components/CardLink';
import GameLink from 'components/GameLink';
import SetLink from 'components/SetLink';

function GradeReportView() {
  const params = useParams();
  const { gradedId = '' } = params;

  const { data, cardInfo, isFetching } = useGetGradeReportViewQuery({gradedId});

  if (isFetching) {
    return (
      <div>
        Loading...
      </div>
    )
  }

  if (!data) {
    return (
      <div>
        Report does not exist.
      </div>
    )
  }


  const {
    game,
    setName,
    cardName,
    cardNumber,
    finish,
    finalGrade,
    grade,
    centeringGrade,
    cornersGrade,
    edgesGrade,
    surfaceGrade,
  } = data;

  return (
    <>
      <Helmet title={gradedId || ''} />
      <Grid container spacing={6} pt={8} pb={8}>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Box sx={{
            border: `1px solid ${grey[500]}`,
            borderRadius: 8,
            background: grey[300],
            padding: '1rem 0',
          }}>
            <div css={css` padding: 2px; `}>
              <Typography variant='h5' gutterBottom css={css`
                text-transform: uppercase;
              `}>
                Verification Report for Certification
              </Typography>
              <Typography variant='h3' css={css`
                text-transform: uppercase;
                color: ${COLORS.DARK};
                margin-bottom: 2rem;
              `}>
                #{gradedId}
              </Typography>
            </div>
            <Grid container>
              {cardInfo?.image && (
                <Grid item xs={12} sm={4} md={3} textAlign='center' padding='1rem'>
                  <img 
                    src={cardInfo?.image}
                    alt={cardInfo?.cardName}
                    css={css`
                      margin-bottom: 16px;
                      width: 100%;
                      max-width: 400px;
                    `}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={cardInfo?.image ? 8 : 12} md={cardInfo?.image ? 9 : 12}  padding='1rem' >
                <Grid container>
                  <Grid item xs={12} paddingBottom={'2rem'}>
                    <table css={css`
                      width: 100%;
                      max-width: 800px;
                      margin: 0 auto;
                      background: ${grey[200]};
                      border: 3px solid ${grey[400]};
                      td {
                        font-size: 1.2rem;
                        padding: 8px;
                      }
                      td.title {
                        max-width: 300px;
                        text-align: right;
                      }
                      td.value {
                        text-align: left;
                      }
                      tbody tr:nth-of-type(odd){
                        background-color: ${grey[300]};
                      }
                    `}>
                      <tbody>
                        <tr>
                          <td className='title'><strong>Card Game</strong></td>
                          <td className='value'>{<GameLink cardInfo={cardInfo} game={game} />}</td>
                        </tr>
                        <tr>
                          <td className='title'><strong>Card Set</strong></td>
                          <td className='value'>{<SetLink cardInfo={cardInfo} setName={setName} />}</td>
                        </tr>
                        <tr>
                          <td className='title'><strong>Card Name</strong></td>
                          <td className='value'>{<CardLink cardInfo={cardInfo} cardName={cardInfo?.cardName ? cardInfo?.cardName : cardName} />}</td>
                        </tr>
                        <tr>
                          <td className='title'><strong>Card Number</strong></td>
                          <td className='value'>{cardNumber}</td>
                        </tr>
                        <tr>
                          <td className='title'><strong>Card Finish</strong></td>
                          <td className='value'>{finish}</td>
                        </tr>
                      </tbody>
                    </table>
                  </Grid>
                  <Grid item xs={12} textAlign='center'>
                    <Grade
                      title='Grade'
                      grade={String(finalGrade)}
                      gradeTitle={grade}
                      full={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      {centeringGrade && (
                        <Grade
                          title='Centering'
                          grade={String(centeringGrade)}
                        />
                      )}
                      {cornersGrade && (
                        <Grade
                          title='Corners'
                          grade={String(cornersGrade)}
                        />
                      )}
                      {edgesGrade && (
                        <Grade
                          title='Edges'
                          grade={String(edgesGrade)}
                        />
                      )}
                      {surfaceGrade && (
                        <Grade
                          title='Surface'
                          grade={String(surfaceGrade)}
                        />                        
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default GradeReportView;
