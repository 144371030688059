/** @jsxImportSource @emotion/react */
import React, { useState, useCallback } from 'react';
import { css } from '@emotion/react';
import { debounce } from 'lodash';

import { useGetSearchResultsQuery } from 'services/pcgPublic';

import { grey } from '@mui/material/colors';

import {
  Container,
  Box,
  Typography,
  ClickAwayListener,
  Collapse,
} from '@mui/material';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import BackgroundImage from 'images/card-search-bg.png';

import SearchCardsRowTable from 'components/Tables/SearchCardsRowTable';
import SearchCardsAdvanced from 'components/SearchCardsAdvanced';

import { sanitizeURISlug } from 'helpers/routing';

const constructBasicSearchQueryString = (queryString: string) => {
  if (!queryString) {
    return '';
  }
  return `&cardName=${sanitizeURISlug(queryString)}&setName=${sanitizeURISlug(queryString)}&gradedId=${sanitizeURISlug(queryString)}`
}

const constructAdvSearchQueryString = (advSearchValue: any) => {
  const queryString = Object.keys(advSearchValue).reduce((acc: any, key: string) => {
    if (advSearchValue[key] !== '') {
      acc += `&${key}=${advSearchValue[key]}`;
      return acc;
    }
    return acc;
  }, '');
  if (queryString !== '') {
    return `${queryString}&useOr=false`
  }
  return '';
}

const SearchCardsRow: React.FC = () => {
  const [value, setValue] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const [advSearchValue, setAdvSearchValue] = useState({});
  const [showAdvSearch, setShowAdvSearch] = useState(false);

  const [page, setPage] = useState(0);
  
  const rowsPerPage = 10; 

  const queryString = showAdvSearch 
    ? constructAdvSearchQueryString(advSearchValue)
    : constructBasicSearchQueryString(searchValue);

  const { data, error, isFetching } = useGetSearchResultsQuery(
    { 
      queryString,
      offset: page * rowsPerPage,
      limit: rowsPerPage,
    },
    { skip: queryString === ''}
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebounce = useCallback(
    debounce((_searchVal: string) => {
      setPage(0);
      setSearchValue(_searchVal);
    }, 300),
    []
  );

  const handleOnChange = (value: string) => {
    setValue(value);
    handleDebounce(value);
  }

  const showResults = queryString !== '';

  return (
    <div
      css={css`
        min-height: 25vh;
        background-color: ${grey[700]};
        background-image: url(${BackgroundImage});
        background-size: cover;
        text-align: center;
        color: white;
      `}
    >
      <Container maxWidth='xl' style={{ minHeight: '25vh' }}>
        <Box sx={{
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '25vh',
          display: 'flex',
        }}>
          <Box
            sx={{
              width: '100%',
              maxWidth: 600,
              textAlign: 'left'
            }}
            paddingTop={8}
            paddingBottom={8}
          >
            <Typography variant='h4' textAlign='center'>
              Cert Lookup & Pop Search
            </Typography>
            <Paper
              sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
              css={css`
                border-radius: ${showResults || showAdvSearch ? '4px 4px 0 0' : '4px'};
              `}
            >
              <IconButton sx={{ p: '10px' }} aria-label='menu' onClick={() => setShowAdvSearch(!showAdvSearch)}>
                {showAdvSearch ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </IconButton>
              <Divider sx={{ height: 28, m: 0.5 }} orientation='vertical' />
              {showAdvSearch ? (
                <Typography variant='h6' paddingLeft={2}>
                  Advanced Search
                </Typography>
              ) : (
                <>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    value={value}
                    onChange={(event) => handleOnChange(event.target.value)}
                    placeholder='Cert Number, Card Name or Set Name'
                    inputProps={{ 'aria-label': 'Cert Number, Card Name or Set Name' }}
                    onFocus={() => handleDebounce(value)}
                  />
                  {value ? (
                    <IconButton sx={{ p: '10px' }} aria-label='clear' onClick={(e: any) => {
                      e.preventDefault();
                      setSearchValue('');
                      setValue('');
                    }} style={{ cursor: 'pointer '}}>
                      <ClearIcon />
                    </IconButton>
                  ) : (
                    <IconButton sx={{ p: '10px' }} aria-label='search'>
                      <SearchIcon />
                    </IconButton>
                  )}
                </>
              )}
            </Paper>
            <Collapse orientation="vertical" in={showAdvSearch} collapsedSize={0}>
              <Paper
                css={css`
                  max-width: 900px;
                  margin: 0 auto;
                  border-radius: ${showResults ? '0' : '0 0 4px 4px'};
                  padding: 8px;
                `}
              >
                <SearchCardsAdvanced onSearch={(value: any) => setAdvSearchValue(value)} />
              </Paper>
            </Collapse>
            {showResults && (
              <div
                css={css`
                  position: absolute;
                  left: 0;
                  width: 100%;
                  z-index: 10;
                `}
              >
                <ClickAwayListener
                  onClickAway={() => setSearchValue('')}
                >
                  <Paper
                    elevation={16}
                    css={css`
                      max-width: 900px;
                      margin: 0 auto;
                    `}
                  >
                    <div style={{ width: '100%' }}>
                      {error ? (
                        <div><em>There was en error with the search.</em></div>
                      ) : (
                        <SearchCardsRowTable
                          data={data || {}}
                          isFetching={isFetching}
                          onClear={() => setSearchValue('')}
                          page={page}
                          rowsPerPage={rowsPerPage}
                          onChangePage={(value: number) => setPage(value)}
                        />
                      )}
                    </div>
                  </Paper>
                </ClickAwayListener>
              </div>
            )}
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default SearchCardsRow;