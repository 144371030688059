import { useNavigate } from "react-router-dom";
import { updateParams } from "helpers/routing";

import {
  TablePagination,
} from "@mui/material";

interface EnhancedPaginationProps {
  rowsPerPageOptions?: number[]
  count?: number
  rowsPerPage: number
  page: number
  onChangePage: any
  onChangeRowsPerPage: any
}

const EnhancedPagination = (props: EnhancedPaginationProps) => {
  const {
    rowsPerPageOptions,
    count = -1,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
  } = props;

  const navigate = useNavigate();

  const handleChangePage = (_event: any, newPage: number) => {
    updateParams("page", String(newPage), navigate);
    onChangePage(+newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    updateParams("page", String(0), navigate);
    updateParams("perPage", String(event.target.value), navigate);
    onChangeRowsPerPage(+event.target.value);
  };

  return (
    <>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        count={count}
        rowsPerPage={rowsPerPage}
        page={count === 0 ? 0 : page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

EnhancedPagination.defaultProps = {
  rowsPerPageOptions: [5, 10, 25, 50],
  count: undefined,
};

export default EnhancedPagination;
