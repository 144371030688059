/* eslint-disable @typescript-eslint/naming-convention */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';

import {
  Box,
  Typography,
  Breadcrumbs as MuiBreadcrumbs,
} from '@mui/material';

interface Crumb {
  url: string | null;
  text: string;
}

interface BreadcrumbsProps {
  crumbs: Crumb[];
}

const Breadcrumbs = (props: BreadcrumbsProps) => {
  const { crumbs } = props;
  return (
    <Box pb={2}>
      <MuiBreadcrumbs
        aria-label='breadcrumb' 
        style={{
          border: '1px solid #212121',
          padding: '1rem 2rem',
          borderRadius: '1rem',
          background: 'linear-gradient(90deg,  #fff 0%, #debb68 170%)',
        }}
      >
        {crumbs.map((crumb: Crumb) => {
          const { url, text } = crumb;
          if (url) {
            return (
              <MuiLink component={Link} to={url} key={url} underline='hover' css={css`
                text-decoration: underline;
                text-decoration-color: #cf974f;
                color: black;
                transition: all .2s ease-out;
                :hover {
                  text-decoration-color: black;
                  transition: all .2s ease-out;
                }
              `}>
                {text}
              </MuiLink>
            );
          }
          return <Typography color='textPrimary' variant='h6' key={url}>{text}</Typography>;
        })}
      </MuiBreadcrumbs>
    </Box>
  );
};

export default Breadcrumbs;
