/** @jsxImportSource @emotion/react */
import React from 'react';
import GlobalClasses from './GlobalClasses';
import { css } from '@emotion/react';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import {
  Box,
  Container,
} from '@mui/material';

import { COLORS } from 'constants/colors';

import AppBar from './AppBar';
import Footer from './Footer';
import SearchCardsRow from 'components/SearchCardsRow';

import { useGetGameInfoQuery } from 'services/pcgPublic';

const HEADER_FONT_FAMILY = [
  'Montserrat', 
  'Helvetica Neue',
  'Arial',
  'sans-serif'
].join(',')

const theme = createTheme({
  typography: {
    fontFamily: [
      'Helvetica', 
      'Helvetica Neue',
      'Arial',
      'sans-serif'
    ].join(','),
    h1: {
      fontFamily: HEADER_FONT_FAMILY
    },
    h2: {
      fontFamily: HEADER_FONT_FAMILY
    },
    h3: {
      fontFamily: HEADER_FONT_FAMILY
    },
    h4: {
      fontFamily: HEADER_FONT_FAMILY
    },
    h5: {
      fontFamily: HEADER_FONT_FAMILY
    },
    h6: {
      fontFamily: HEADER_FONT_FAMILY
    },
  },
  palette: {
    primary: {
      main: '#cf974f',
    },
    secondary: {
      main: '#debb68',
    },
  },
});

type LayoutPropsType = {
  children: any
};

const Layout: React.FC<LayoutPropsType> = (props: LayoutPropsType) => {
  const { children } = props;
  const { isFetching } = useGetGameInfoQuery();

  return (
    <ThemeProvider theme={theme}>
      <GlobalClasses />
      {isFetching ? (
        <></>
      ) : (
        <Box sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}>
          <Box>
            <AppBar />
            <SearchCardsRow />
          </Box>
          <Box sx={{
            flexGrow: 1,
          }}>
            <Container
              css={css`
                background-color: ${COLORS.LIGHT};
                padding-top: 2rem;
                padding-bottom: 2rem;
              `}
              maxWidth='xl'
            >
              {children}
            </Container>
          </Box>
          <Box>
            <Footer />            
          </Box>
        </Box>
      )}
    </ThemeProvider>
  );
};

export default Layout;