import React from 'react';
import { Global, css } from '@emotion/react';

import { 
  COLORS
} from 'constants/colors'

const GlobalClasses: React.FC = () => {
  return (
    <Global
      styles={css`\
        body {
          background: ${COLORS.LIGHT};
        }
        .some-class {
          color: hotpink !important;
        }
      `}
    />
  );
};

export default GlobalClasses;