export const DEFAULT_TABLE_REDUCER_VALUES = {
  page: 0,
  offset: 0,
  limit: 10,
  order: "desc",
  orderBy: "created_at",
  sort: { "desc": "created_at" },
  status: "ANY",
  dateRange: 90,
  search: "",
};
