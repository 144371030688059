import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';

import {
  HelmetProvider,
  Helmet,
} from 'react-helmet-async';

import { Provider } from 'react-redux';
import { store } from 'store';

import HomeView from 'views/HomeView';
import GameView from 'views/GameView';
import SetView from 'views/SetView';
import CardView from 'views/CardView';
import CardGradeView from 'views/CardGradeView';
import GradeReportView from 'views/GradeReportView';

import Layout from 'App/Layout';

function App() {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <Helmet
          titleTemplate="%s | Population Report | Premier Card Grading"
          defaultTitle="Population Report | Premier Card Grading"
        />
        <BrowserRouter>
          <Layout>
            <Routes>
              <Route path='/' element={<HomeView />} />
              <Route path='/report/:gradedId' element={<GradeReportView />} />
              <Route path=':gameCode' element={<GameView />} />
              <Route path=':gameCode/:setName' element={<SetView />} />
              <Route path=':gameCode/:setName/:number' element={<CardView />} />
              <Route path=':gameCode/:setName/:number/:finalGrade' element={<CardGradeView />} />
            </Routes>
          </Layout>
        </BrowserRouter>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
