import { useState, useEffect, useReducer } from 'react';
import { Link } from 'react-router-dom';

import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Skeleton,
} from '@mui/material';

import {
  tableReducer,
  dispatchFiltersChange,
  dispatchChangePage,
  dispatchChangeRowsPerPage,
  sortGameSetsTable,
} from "helpers/tables";

import { DEFAULT_TABLE_REDUCER_VALUES } from "constants/tables";

import EnhancedTableHead from 'components/Tables/EnhancedTableHead';
import CellGeneratedRow from 'components/Tables/CellGeneratedRow';
import EnhancedPagination from 'components/Tables/EnhancedPagination';
import NoResultsRow from 'components/Tables/NoResultsRow';

import { Set } from 'types/gameInfo';
import { CellGeneratedTableRow } from 'types/tables';

interface GameSetsTableProps {
  data: Set[];
  isFetching: boolean;
  gameCode: string;
}

function GameSetsTable(props: GameSetsTableProps) {
  const { data, isFetching, gameCode } = props;

  const [count, setCount] = useState(0);

  const [variables, dispatch] = useReducer(tableReducer, {
    ...DEFAULT_TABLE_REDUCER_VALUES,
    orderBy: "cardsGraded",
    limit: 25,
  });
  
  const { page, limit, order, orderBy } = variables;

  useEffect(() => {
    if (!isFetching) {
      setCount(data?.length || 0);
    }
  }, [data, isFetching]);

  const HEAD_CELLS: CellGeneratedTableRow[] = [
    { id: "cardsGraded", width: 150, label: "Total Grades", display: ({ cardsGraded }: Set) => cardsGraded || null },
    { id: "setName", label: "Set Name", display: ({ setName }: Set) => {
      return (
        <Link to={`/${gameCode}/${setName}`}>{setName}</Link>
      )
    } },
    { id: "setCategory", width: 150, label: "Category", display: ({ setCategory }: Set) => setCategory || null },
    { id: "releaseDate", width: 150, label: "Release Date", display: ({ releaseDate }: Set) => releaseDate || null },
  ];

  const filteredData = data?.slice()
    .sort(sortGameSetsTable(order, orderBy))|| [];

  return (
    <Paper variant="outlined">
      <TableContainer>
        <Table>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={(_e: any, property: string) => dispatchFiltersChange({ key: "sort", property, orderBy, order }, dispatch)}
            headCells={HEAD_CELLS}
          />
          <TableBody>
            {isFetching ? (
              Array.from(Array(limit)).map((_e: any, i: number) => {
                const key = `empty_${i}`;
                return (
                  <TableRow key={key}>
                    <TableCell colSpan={HEAD_CELLS.length}>
                      <Skeleton height={28} />
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <>
                {filteredData?.length > 0 ? (
                  filteredData?.slice(page * limit, (page * limit) + limit).map((row: any) => (
                    <CellGeneratedRow
                      key={`${row.id}`}
                      row={row} 
                      cells={HEAD_CELLS} 
                    />
                  ))
                ) : (
                  <NoResultsRow colSpan={HEAD_CELLS.length} data-testid="no-results-display" />
                )}
              </>
            )}
            <TableRow>
              <EnhancedPagination
                count={count}
                rowsPerPage={limit}
                page={count === 0 ? 0 : page}
                onChangePage={(newPage: number) => dispatchChangePage(newPage, limit, dispatch)}
                onChangeRowsPerPage={(rowsPerPage: number) => dispatchChangeRowsPerPage(rowsPerPage, dispatch)}
              />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default GameSetsTable;
