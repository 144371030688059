import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Skeleton,
  TablePagination,
} from '@mui/material';

import EnhancedTableHead from 'components/Tables/EnhancedTableHead';
import CellGeneratedRow from 'components/Tables/CellGeneratedRow';
import NoResultsRow from 'components/Tables/NoResultsRow';

import CardLink from 'components/CardLink';
import GameLink from 'components/GameLink';
import GradeReportLink from 'components/GradeReportLink';
import SetLink from 'components/SetLink';

import { CardViewRow } from 'types/gameInfo';
import { CellGeneratedTableRow } from 'types/tables';

interface SearchCardsRowTableProps {
  data: any;
  isFetching: boolean;
  onClear: any;
  page: number;
  rowsPerPage: number;
  onChangePage: any;
}

function SearchCardsRowTable(props: SearchCardsRowTableProps) {
  const {
    data,
    isFetching,
    onClear,
    page,
    rowsPerPage,
    onChangePage,
  } = props;

  const {
    data: filteredData,
    totalElements,
    // totalPages,
  } = data;

  const HEAD_CELLS: CellGeneratedTableRow[] = [
    { id: "gradedId", width: 80, sortable: false, label: "Grade #", display: ({ gradedId }: CardViewRow) => <GradeReportLink gradedId={gradedId} onClick={onClear} />},
    { id: "game", width: 150, sortable: false, label: "Game", display: ({ game, cardInfo }: CardViewRow) => <GameLink cardInfo={cardInfo} game={game} onClick={onClear} /> },
    { id: "cardName", sortable: false, label: "Card Name", display: ({ cardName, cardInfo }: CardViewRow) => <CardLink cardInfo={cardInfo} cardName={cardName} onClick={onClear} />},
    { id: "setName", sortable: false, label: "Set Name", display: ({ setName, cardInfo }: CardViewRow) => <SetLink cardInfo={cardInfo} setName={setName} onClick={onClear} /> },
    { id: "finalGrade", sortable: false, label: "Grade", display: ({finalGrade, grade}: CardViewRow) => <span>{finalGrade} ({grade})</span> },
  ];

  return (
    <Paper variant="outlined">
      <TableContainer>
        <Table>
          <EnhancedTableHead
            order={'desc'}
            orderBy={'gradedId'}
            headCells={HEAD_CELLS}
          />
          <TableBody>
            {isFetching ? (
              Array.from(Array(rowsPerPage)).map((_e: any, i: number) => {
                const key = `empty_${i}`;
                return (
                  <TableRow key={key}>
                    <TableCell colSpan={HEAD_CELLS.length}>
                      <Skeleton height={20} />
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <>
                {filteredData?.length > 0 ? (
                  filteredData?.map((row: any) => (
                    <CellGeneratedRow
                      key={`${row.gradedId}`}
                      row={row} 
                      cells={HEAD_CELLS} 
                    />
                  ))
                ) : (
                  <NoResultsRow colSpan={HEAD_CELLS.length} data-testid="no-results-display" />
                )}
              </>
            )}
            <TableRow>
              <TablePagination
                count={totalElements || 0}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[rowsPerPage]}
                page={page === 0 ? 0 : page}
                onPageChange={(_event: any, newPage: number) =>  onChangePage(+newPage)}
              />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default SearchCardsRowTable;
