import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import {
  Box,
  Grid,
} from '@mui/material';

import FilterSearch from 'components/FilterSearch';
import FilterGradeDDL from 'components/FilterGradeDDL';
import SetViewTable from 'components/Tables/SetViewTable';
import Breadcrumbs from 'components/Breadcrumbs';

import { filterRowContains, filterCardsGraded } from 'helpers/tables';
import { useGetGameInfo, useGetCardInfoQuery } from 'services/pcgPublic';

function SetView() {
  const params = useParams();
  const { gameCode = '', setName = '' } = params;

  const [ search, setSearch ] = useState('');
  const [ filterGrade, setFilterGrade ] = useState('');
  
  const gameInfo = useGetGameInfo(gameCode);
  const { data, isFetching } = useGetCardInfoQuery({gameCode, setName});

  useEffect(() => {
    setSearch('');
    setSearch('');
  }, [gameCode, setName])

  const filteredData = data?.slice().filter(filterCardsGraded(filterGrade)).filter(filterRowContains(search))

  return (
    <>
      <Helmet title={setName || ''} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Breadcrumbs 
            crumbs={[
              {
                url: '/',
                text: 'Home',
              },
              {
                url: `/${gameCode}`,
                text: gameInfo.game || '',
              },
              {
                url: '',
                text: setName || '',
              },
            ]} 
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            maxWidth: '100%',
            flexWrap: 'wrap',
          }}>
            <Box>
              <FilterGradeDDL
                onChange={(value: string) => setFilterGrade(value)}
              />
            </Box>
            <Box>
              <FilterSearch 
                onChange={(value: string) => setSearch(value)}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 0 }}>
          <SetViewTable
            data={filteredData || []}
            isFetching={isFetching}
            gameCode={gameCode}
            setName={setName}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default SetView;
