import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import { CardViewRow } from "types/gameInfo";

type FinishsGradeCounts = {
    [finish: string]: {
        count: number;
        [grade: string]: number;
    };
};

type FinishTableProps = {
    data: CardViewRow[];
};

const gradeMapping: { [key: string]: string } = {
    "Gold 10.0": "Pristine 10 (Quad)",
    "Pristine": "Pristine 10",
    "Gem Mint 10": "Gem Mint 10",
    "Gem Mint": "9.5",
    "Mint": "9",
    "NM-Mint+": "8.5",
    "NM-Mint": "8",
    "Near Mint+": "7.5",
    "Near Mint": "7",
    "EX-NM": "6",
    "Excellent": "5",
    "Very Good": "4",
    "Good": "3",
    "Fair": "2",
    "Poor": "1",
    "Authentic": "A"
};

export const FinishTable = ({ data }: FinishTableProps) => {
    const finishsGradeCounts: FinishsGradeCounts = data.reduce(
        (acc: any, { finish, grade }) => {
            if (acc[finish]) {
                acc[finish].count++;
            } else {
                acc[finish] = { count: 1 };
            }

            const mappedGrade = gradeMapping[grade];
            if (!acc[finish][mappedGrade]) {
                acc[finish][mappedGrade] = 0;
            }
            acc[finish][mappedGrade]++;

            return acc;
        },
        {}
    );

    // Fill in missing grades for easier table rendering
    Object.keys(finishsGradeCounts).forEach(finish => {
        Object.keys(gradeMapping).forEach(grade => {
            const mappedGrade = gradeMapping[grade];
            if (!finishsGradeCounts[finish][mappedGrade]) {
                finishsGradeCounts[finish][mappedGrade] = 0;
            }
        });
    });

    // Get the list of grades with non-zero counts
    const nonZeroGrades = Object.keys(gradeMapping).filter(grade =>
        Object.values(finishsGradeCounts).some(counts => counts[gradeMapping[grade]] > 0)
    );

    return (
        <TableContainer component={Paper}>
            <Table aria-label="finish table">
                <TableHead>
                    <TableRow>
                        <TableCell>Finish</TableCell>
                        <TableCell align="center">Total Grades</TableCell>
                        {nonZeroGrades.map(grade => (
                            <TableCell align="center" key={grade}>{gradeMapping[grade]}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(finishsGradeCounts).map(
                        ([finish, counters], index) => (
                            <TableRow
                                key={index}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                                <TableCell align="center" component="th" scope="finish">
                                    {finish}
                                </TableCell>
                                <TableCell align="center" component="th" scope="count">
                                    {counters.count}
                                </TableCell>
                                {nonZeroGrades.map(grade => (
                                    <TableCell align="center" key={grade}>{counters[gradeMapping[grade]]}</TableCell>
                                ))}
                            </TableRow>
                        )
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
