/** @jsxImportSource @emotion/react */
import { useState } from "react";

import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

interface FilterGradeDDLProps {
  onChange: any;
}

const FilterGradeDDL = (props: FilterGradeDDLProps) => {
  const { onChange } = props;
  const [value, setValue] = useState("");

  const handleOnChange = (value: string) => {
    setValue(value);
    onChange(value);
  };

  return (
    <div style={{ paddingRight: 8, width: 200 }}>
      <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
        <InputLabel id="filter-grade-ddl-label">Select Grade</InputLabel>
        <Select
          labelId="filter-grade-ddl-label"
          id="filter-grade-ddl"
          value={value}
          label="Select Grade"
          onChange={event => handleOnChange(event.target.value)}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={"Gold 10.0"}>Pristine 10 (Quad)</MenuItem>
          <MenuItem value={"10.0"}>Pristine 10</MenuItem>
          <MenuItem value={"Gem Mint 10.0"}>Gem Mint 10</MenuItem>
          <MenuItem value={"9.5"}>9.5</MenuItem>
          <MenuItem value={"9.0"}>9</MenuItem>
          <MenuItem value={"8.5"}>8.5</MenuItem>
          <MenuItem value={"8.0"}>8</MenuItem>
          <MenuItem value={"7.5"}>7.5</MenuItem>
          <MenuItem value={"7.0"}>7</MenuItem>
          <MenuItem value={"6.0"}>6</MenuItem>
          <MenuItem value={"5.0"}>5</MenuItem>
          <MenuItem value={"4.0"}>4</MenuItem>
          <MenuItem value={"3.0"}>3</MenuItem>
          <MenuItem value={"2.0"}>2</MenuItem>
          <MenuItem value={"1.0"}>1</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default FilterGradeDDL;
