
import { Link } from 'react-router-dom';
import { CardInfo } from 'types/gameInfo';

interface GameLinkProps {
  cardInfo: CardInfo | null;
  game: string;
  onClick?: any;
}

const GameLink = ({cardInfo, game, onClick = null} : GameLinkProps) => {
  if (!cardInfo?.gameCode) {
    return <>{game}</>;
  }

  return (
    <Link to={`/${cardInfo.gameCode}`} onClick={onClick}>{game}</Link>
  )
}

export default GameLink;